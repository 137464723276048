<template>
  <b-card
    no-body
  >
    <b-card-body class="pb-0">
      <div class="d-flex align-items-center">
        <h2 class="font-weight-bolder">
          {{ totalSlots }}
        </h2>
        <b-badge
          pill
          variant="light-warning"
          class="font-small-1 ml-50"
        >
          {{ totalApartments }} {{ t('tòa nhà') }}
        </b-badge>
      </div>

      <h6 class="text-muted">
        {{ t('Đơn vị thuê') }} <feather-icon
          v-b-tooltip.hover.v-secondary
          :title="t('Bao gồm tất cả giường và tất cả phòng (không tính phòng có giường)')"
          class="mr-25 text-secondary"
          icon="InfoIcon"
        />
      </h6>

    </b-card-body>

    <b-row
      class="my-2 mx-0"
    >
      <b-col>
        <div
          class="rounded border p-50 text-center"
          style="{border-style: dotted !important;}"
        >
          <h2 class="font-weight-bolder font-medium-1">
            {{ totalRooms }}
          </h2>
          <h6 class="text-muted font-small-2">
            {{ t('Phòng') }}
          </h6>
        </div>
      </b-col>
      <b-col v-if="totalBeds > 0">
        <div
          class="rounded border p-50 text-center"
          style="{border-style: dotted;}"
        >
          <h2 class="font-weight-bolder font-medium-1">
            {{ totalBeds }}
          </h2>
          <h6 class="text-muted font-small-2">
            {{ t('Giường') }}
          </h6>
        </div>
      </b-col>

    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardBody, VBTooltip, BBadge,
} from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    apartment: {
      type: [Object, null],
      default: null,
    },
  },
  data: () => ({
    totalApartments: 0,
    totalRooms: 0,
    totalBeds: 0,
    totalSlots: 0,
  }),

  watch: {
    apartment() {
      this.fetchData(this.apartment);
    },
  },
  created() {
    this.fetchData(null);
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    fetchData(apartment) {
      useJwt.getReportPropertySlotsData(apartment).then(response => {
        this.totalApartments = response.data.totalApartments;
        this.totalSlots = response.data.totalSlots;
        this.totalRooms = response.data.totalRooms;
        this.totalBeds = response.data.totalBeds;
      });
    },
  },
};
</script>
