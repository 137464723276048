<template>
  <b-card
    no-body
  >
    <b-card-body class="pb-0">
      <div class="d-flex align-items-center">
        <h2 class="font-weight-bolder">
          {{ totalTenants }}
        </h2>
        <b-badge
          pill
          variant="light-primary"
          class="font-small-1 ml-50"
        >
          {{ totalNewTenants }} {{ t('khách mới') }}
        </b-badge>
      </div>

      <h6 class="text-muted">
        {{ t('Khách hàng') }}
      </h6>

    </b-card-body>

    <b-row
      class="my-2 mx-0"
    >
      <b-col cols="4">
        <div
          class="rounded border p-50 text-center"
          style="{border-style: dotted !important;}"
        >
          <h2 class="font-weight-bolder font-medium-1">
            {{ totalMales }}
          </h2>
          <h6 class="text-muted font-small-2">
            {{ t('Nam') }}
          </h6>
        </div>
      </b-col>
      <b-col cols="4">
        <div
          class="rounded border p-50 text-center"
          style="{border-style: dotted;}"
        >
          <h2 class="font-weight-bolder font-medium-1">
            {{ totalFemales }}
          </h2>
          <h6 class="text-muted font-small-2">
            {{ t('Nữ') }}
          </h6>
        </div>
      </b-col>
      <b-col cols="4">
        <div
          class="rounded border p-50 text-center"
          style="{border-style: dotted;}"
        >
          <h2 class="font-weight-bolder font-medium-1">
            {{ totalOthers }}
          </h2>
          <h6 class="text-muted font-small-2">
            {{ t('Khác') }}
          </h6>
        </div>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BBadge, BRow, BCol, BCardBody,
} from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    apartment: {
      type: [Object, null],
      default: null,
    },
  },
  data: () => ({
    totalTenants: 0,
    totalNewTenants: 0,
    totalMales: 0,
    totalFemales: 0,
    totalOthers: 0,
  }),
  watch: {
    apartment() {
      this.fetchData(this.apartment);
    },
  },
  created() {
    this.fetchData(null);
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    fetchData(apartment) {
      useJwt.getReportTenantGeneralData(apartment).then(response => {
        this.totalTenants = response.data.total;
        this.totalNewTenants = response.data.newThisMonth;
        this.totalMales = response.data.male;
        this.totalFemales = response.data.female;
        this.totalOthers = response.data.other;
      });
    },
  },
};
</script>
