<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-card-body class="pb-0">
      <div class="d-flex align-items-center">
        <h2 class="font-weight-bolder">
          {{ Number(data.total).toLocaleString() }}
        </h2>
        <b-badge
          v-if="data && data.changes && data.changes !== 0"
          pill
          :variant="data.changes >= 0 ? 'light-primary' : 'light-danger'"
          class="font-small-1 ml-50"
        >
          <feather-icon :icon="data.changes >= 0 ? 'ArrowUpIcon': 'ArrowDownIcon'" />
          {{ Math.abs(Number(data.changes)) }}%
        </b-badge>
      </div>

      <h6 class="text-muted">
        {{ title }}
      </h6>
    </b-card-body>

    <vue-apex-charts
      class="mx-0"
      type="area"
      height="100"
      width="100%"
      :options="chartData.options"
      :series="chartData.series"
    />

  </b-card>
</template>

<script>
import {
  BCard, BBadge, BCardBody,
} from 'bootstrap-vue';
import { $themeColors } from '@themeConfig';
import VueApexCharts from 'vue-apexcharts';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BCard,
    BBadge,
    BCardBody,
    VueApexCharts,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    type: {
      type: Number,
      default: 1,
    },
    apartment: {
      type: [Object, null],
      default: null,
    },
  },

  data() {
    return {
      // Area charts
      chartData: {
        series: [
          {
            name: 'Doanh thu',
            data: [10, 15, 8, 15, 27, 12, 20, 0, 0, 8, 15, 27, 12, 20],
          },

        ],
        options: {
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.9,
              opacityFrom: 0.8,
              opacityTo: 0.5,
              stops: [0, 100, 100, 100],
            },
          },

          xaxis: {
            type: 'numeric',
          },
          yaxis: [
            {
              y: 0,
              offsetX: 0,
              offsetY: 0,
              padding: {
                left: 0,
                right: 0,
              },

            },
          ],
          tooltip: {
            x: { show: false },
            y: { show: false },
          },
          theme: {
            monochrome: {
              enabled: true,
              color: $themeColors[this.variant],
              shadeTo: 'light',
              shadeIntensity: 0.9,
            },
          },
        },
      },
      data: null,
    };
  },
  watch: {
    apartment() {
      this.fetchData(this.apartment);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(apartment) {
      if (this.type === 1) {
        useJwt.getReportFinanceIncomeThisMonth(apartment).then(response => {
          this.data = response.data;
        });
      } else if (this.type === 2) {
        useJwt.getReportFinanceExpenseThisMonth(apartment).then(response => {
          this.data = response.data;
        });
      } else if (this.type === 3) {
        useJwt.getReportFinanceIncomeAllocationThisMonth(apartment).then(response => {
          this.data = response.data;
        });
      } else if (this.type === 4) {
        useJwt.getReportFinanceExpenseAllocationThisMonth(apartment).then(response => {
          this.data = response.data;
        });
      }
    },
  },

};
</script>
