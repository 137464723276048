<template>
  <div>
    <b-row class="match-height">
      <b-col>
        <h2 class="font-medium-3">
          {{ t('Bảng tin') }}
        </h2>
      </b-col>
      <b-col
        lg="2"
        sm="6"
        md="3"
      >
        <select-apartment v-model="apartment" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <medal-card :apartment="apartment" />
      </b-col>
      <!-- <b-col
        xxl="6"
        xl="6"
        lg="6"
        md="12"
        xs="12"
      >
        <dashboard-item />
      </b-col> -->

    </b-row>
    <b-row class="match-height">

      <b-col
        xl="3"
        lg="6"
      >
        <property-card :apartment="apartment" />
      </b-col>
      <b-col
        xl="3"
        lg="6"
      >
        <tenant-card :apartment="apartment" />
      </b-col>
      <b-col
        xl="3"
        lg="6"
      >
        <contract-card :apartment="apartment" />
      </b-col>
      <b-col
        xl="3"
        lg="6"
      >
        <task-card :apartment="apartment" />
      </b-col>

    </b-row>
    <b-row>
      <b-col
        xl="3"
        lg="6"
      >
        <finance-card
          :title="t('Tiền thu trong tháng')"
          variant="primary"
          :type="1"
          :apartment="apartment"
        />
      </b-col>
      <b-col
        xl="3"
        lg="6"
      >
        <finance-card
          :title="t('Tiền chi trong tháng')"
          variant="danger"
          :type="2"
          :apartment="apartment"
        />
      </b-col>
      <b-col
        xl="3"
        lg="6"
      >
        <finance-card
          :title="t('Doanh thu phân bổ')"
          variant="primary"
          :type="3"
          :apartment="apartment"
        />
      </b-col>
      <b-col
        xl="3"
        lg="6"
      >
        <finance-card
          :title="t('Chi phí phân bổ')"
          variant="danger"
          :type="4"
          :apartment="apartment"
        />
      </b-col>
    </b-row>
  </div>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';

// eslint-disable-next-line import/no-cycle
import SelectApartment from '@/views/components/SelectApartment.vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import MedalCard from './items/MedalCard.vue';
import TenantCard from './items/TenantCard.vue';
import FinanceCard from './items/FinanceCard.vue';
import PropertyCard from './items/PropertyCard.vue';
import ContractCard from './items/ContractCard.vue';
import TaskCard from './items/TaskCard.vue';

export default {
  components: {
    BRow,
    BCol,
    MedalCard,
    TenantCard,
    SelectApartment,
    FinanceCard,
    PropertyCard,
    ContractCard,
    TaskCard,
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  data() {
    return {
      apartment: null,
    };
  },
};
</script>
